@import "modules/responsive-type.scss";

$primary: #d31820;/* MAIN COLOR */
$secondary: #27277f; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 16px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

@import url('https://fonts.googleapis.com/css?family=Katibeh|Lobster');
// font-family: 'Lobster', cursive;
// font-family: 'Katibeh', cursive;

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
	font-family: 'Katibeh', cursive;
    font-size: 1rem;
}

h1 { 
	font-family: 'Lobster', cursive;
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {
font-family: 'Lobster', cursive;  
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
	font-family: 'Lobster', cursive;
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}


// .container .privacy h2{
// 	margin-top: -50px !important;

// }

#terms_header h2{
    visibility: hidden;
    &:before {
        content: 'Terms Of Use / Disclaimer:';
        visibility: visible;
    }
    
    font-size: 1.70em !important;
    // margin-top: -30px;
    margin-bottom: 0px;	

    @media (max-width: 986px) {
    	// margin-top: 25px; 	
       	margin-bottom: -20px;
    }

    @media (max-width: 450px) {
    	// margin-top: 25px; 	
       	margin-bottom: -20px;
    }

}

h3 {
    font-size: 1.6rem;    
    text-transform: lowercase;  

&:first-letter {
    text-transform: uppercase;
    }
}


body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.20);

    .navbar-nav {
        >li>a {
			font-family: 'Lobster', cursive;
            font-size: 1rem;
            color: $secondary !important;
            text-align: center;
            margin-top: 25px;
            display: flex;
            align-items: center;
            color: $blk;
            border-radius: $border-radius;
            transition-duration: 0.5s;


            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht !important;

                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: #000;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 40px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 16px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 18px;
        @media (max-width: 990px) {
            font-size: 16px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 18px;
        @media (max-width: 990px) {
            font-size: 16px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 200px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 18px;
    }
}

.top-pad {
    padding: 1em 0em;
    background: white;

    @media (max-width: 990px) {
        padding: 3em 0em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
        font-size: 1rem;
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}


.shadowthis {
text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.75);
}

.padTop{
	padding-top: 25px;
}

.clipthis {
	clip-path: ellipse(150% 100% at 50% 0);
    -webkit-clip-path: ellipse(150% 100% at 50% 0);
}

#signUpForm {
    padding: 0px 10px !important;
    margin-bottom: -1px;
}



.intro {
	// background: url(../img/banner.jpg);
	background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/banner.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 100% 0%;
	padding: 450px 0px;

	@media (max-width: 991px){
		padding: 250px 0px;
	}

	@media (max-width: 767px){
		background-position: 90% 0%;
		padding: 200px 0px;
	}

	@media (max-width: 568px){
		background-position: 80% 0%;
		padding: 150px 0px;
	}	

		h1, p{
		color:#fff;
	}	
}

.form {

	.fbg{
		// background-color: rgba(0, 0, 0, 0.35);
	}

	.img1{
	    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/img1.jpg);
	    background-repeat: no-repeat;
	    background-size: cover;
	    background-position: 50% 30%;
	    padding: 192px 0px;

	   	@media (max-width: 991px){
	    	padding: 203px 0px;
	    }

        @media (max-width: 990px){
            padding: 207px 0px;
        }

	    @media (max-width: 568px){
	    

	    }

		@media (max-width: 325px){
			
		}


	}

	.img2{
	    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/img2.jpg);
	    background-repeat: no-repeat;
	    background-size: cover;
	    background-position: 50% 50%;
	    padding: 192px 0px;

	   	@media (max-width: 999px){
            // margin-top: -21px;
            background-position: 50% 20%;
            padding: 192px 0px;
	    }


	    @media (max-width: 568px){
	    
	    }

		@media (max-width: 325px){
	
		}
	}

    h1{
        color:#fff;
    }
}

// .divider{
	
// 	background-color: $primary;
// 	padding: 100px 0px;

	
// }


.outro {
	// background: url(../img/banner.jpg);
	background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/banner2.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	padding: 450px 0px;
    margin-top: -6px;


    @media (max-width: 1366px){
        margin-top: -8px;
        background-position: 2% 50%;
    }

    @media (max-width: 1280px){
        margin-top: -12px;
        background-position: 2% 50%;
    }


    @media (max-width: 1080px){
        margin-top: -18px;
        background-position: 2% 50%;
    }

    @media (max-width: 1024px){
        margin-top: -20px;
        background-position: 2% 50%;
    }


	@media (max-width: 991px){
        margin-top: -15px;
        background-position: 2% 50%;
		padding: 250px 0px;
	}

	@media (max-width: 767px){
		background-position: 0% 0%;
		padding: 200px 0px;
	}

	@media (max-width: 568px){
		background-position: 20% 0%;
		padding: 100px 0px;
	}

	h1, h2, p{
		color:#fff;
	}

	i.fa{
        font-size: 100px;
        color: #fff;
        text-shadow: 3px 3px 8px #666;
    
        @media (max-width: 600px) {
            font-size: 50px;
        }
    }

    .sendUp{
    	margin-top: -350px;
    	margin-bottom: 400px;

        @media (max-width: 991px) {
            margin-top: -50px;
            margin-bottom: 20px;
        }
    
		@media (max-width: 568px) {
		    margin-top: -50px;
    		margin-bottom: 20px;
		}
    }
}


.btn {
  font-family: 'Quicksand', sans-serif;
  background-color: $primary;
  // border-color: rgba(255,255,255,1); 
  font-size: 15px;
  padding: 1em 2em;
  color: $wht;

    &:hover {
        background-color: $secondary; 
        //border-color: rgba(255,255,255,1); 
        color: $wht;

        @media (max-width: 1366px) {
            background-color: $primary;
            color: $wht;
        }

    &:active, &:focus {
        background-color: $primary;
        color: $wht !important;
        }
    }
}
